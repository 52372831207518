import { useState } from "react"

export function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null)
  const [copySuccess, setCopySuccess] = useState(false)

  const copy = async text => {
    if (!navigator?.clipboard) {
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      setCopySuccess(true)
      return true
    } catch (error) {
      setCopiedText(null)
      setCopySuccess(false)
      return false
    }
  }

  return [copiedText, copy, copySuccess, setCopySuccess]
}
