import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { useWalletService } from "../casper-wallet/wallet-service"
import { CasperClient, CLPublicKey } from "casper-js-sdk"
import { truncateto5symbols, sumFormat } from "../../utils/utils"
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard"
import { CasperDashContext } from "../Layout"
import { fetchAllInfo } from "../../utils/fetchNetwork"

import iconBufferCopy from "../../assets/icon-wallet-copy.svg"
import iconWalletAccount from "../../assets/icon-wallet-account.svg"
import arrowUpIcon from "../../assets/validator-down-arrow.svg"
import iconBufferCopied from "../../assets/icon-copied.svg"
import casperWalletDisconnect from "../../assets/casper-wallet-disconnect.svg"
import casperWalletUpdate from "../../assets/casper-wallet-update.svg"
import casperWalletPreloader from "../../assets/casper-wallet-preloader.svg"
import casperDashIcon from "../../assets/casperdash-icon.svg"
import casperWalletIcon from "../../assets/casperwallet-icon.svg"

export default function WalletPanel({
  walletType,
  setWalletType,
  setTabActive,
  setSelectedOption,
}) {
  const { activePublicKey, disconnect } = useWalletService()
  // eslint-disable-next-line
  const [isWalletConnected, setIsWalletConnected] = useState(activePublicKey)
  const [walletBalance, setWalletBalance] = useState(null)
  const [isLoadingBalance, setIsLoadingBalance] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  // eslint-disable-next-line
  const [value, copy] = useCopyToClipboard()
  //Casper Dash states
  const { casperDashPublicKeyContext, isCasperDashInstalled } =
    React.useContext(CasperDashContext)
  const [casperDashPublicKey] = casperDashPublicKeyContext

  const handleCopyClick = value => {
    setIsCopied(true)
    copy(value)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  //Casper Wallet interactions
  const casperClient = new CasperClient(
    "https://api.staging.casperecosystem.io/api/rpc"
  )

  const balance = async () => {
    if (isCasperDashInstalled && casperDashPublicKey) {
      return (
        casperDashPublicKey &&
        (await casperClient.balanceOfByPublicKey(
          CLPublicKey.fromHex(casperDashPublicKey)
        ))
      )
    } else {
      return (
        activePublicKey &&
        (await casperClient.balanceOfByPublicKey(
          CLPublicKey.fromHex(activePublicKey)
        ))
      )
    }
  }

  const handleDisconnect = () => {
    if (walletType === "CasperWallet") {
      disconnect()
    }
    if (walletType === "CasperDashWallet") {
      window.casperDashHelper.disconnectFromSite()
    }
    setWalletType("")
    localStorage.setItem("walletInfo", null)
    localStorage.setItem("walletType", null)
  }

  const handleBalance = () => {
    setIsLoadingBalance(true)
    balance()
      .then(balance => {
        setWalletBalance(balance.toString())
        setIsLoadingBalance(false)
      })
      .catch(() => {
        setIsLoadingBalance(false)
      })
  }

  const displayWalletInfo = () => {
    navigate("/stats/?tab=Delegations")
    setTabActive("Delegations")
    setSelectedOption("my-wallet")
  }

  useEffect(() => {
    const publiKey = activePublicKey || casperDashPublicKey
    if (publiKey) {
      setIsWalletConnected(true)
      const savedWalletInfo = JSON.parse(localStorage.getItem("walletInfo"))
      if (!savedWalletInfo || savedWalletInfo.publicKey !== publiKey) {
        fetchAllInfo(publiKey).then(walletInfo => {
          localStorage.setItem("walletInfo", JSON.stringify(walletInfo))
        })
      }
      handleBalance()
    } else {
      setIsWalletConnected(false)
    }
    // eslint-disable-next-line
  }, [activePublicKey, casperDashPublicKey])

  useEffect(() => {
    if (activePublicKey) {
      localStorage.setItem("walletType", "CasperWallet")
    }
  }, [activePublicKey])

  return (
    <div className="wallet-panel">
      <div className="wallet-panel-container">
        {/* Casper Dash Panel  \/\/\/\/\/*/}
        {walletType === "CasperDashWallet" && casperDashPublicKey ? (
          <>
            <div className="wallet-panel__header">
              <img
                src={casperDashIcon}
                alt=""
                className="wallet-panel__header-icon"
              />
              <p>{truncateto5symbols(casperDashPublicKey)}</p>
              <img className="header-arrow" src={arrowUpIcon} alt="" />
            </div>
            <div className="wallet-panel-hover--connected">
              <div
                className="connected-block--grey"
                onClick={handleDisconnect}
                onKeyDown={handleDisconnect}
                tabIndex={0}
                role="button"
              >
                <img
                  alt=""
                  src={casperWalletDisconnect}
                  title="Disconnect Casper Wallet"
                />
                <p>Disconnect</p>
              </div>
              <div
                className="connected-block--grey"
                onClick={() => displayWalletInfo()}
                onKeyDown={() => displayWalletInfo()}
                role="button"
                tabIndex={0}
              >
                <img src={iconWalletAccount} alt="" />
                <p>View Account Info</p>
              </div>
              <div
                className="connected-block--grey"
                title={activePublicKey}
                onClick={() => handleCopyClick(casperDashPublicKey)}
                onKeyDown={() => handleCopyClick(casperDashPublicKey)}
                role="presentation"
              >
                <div className="wallet-iconbuffercopy">
                  <img
                    src={isCopied ? iconBufferCopied : iconBufferCopy}
                    alt=""
                    title="Copy account number"
                  />
                </div>
                Copy Public Key
              </div>
              <div className="connected-block__balance">
                <p className="connected-block__balance--grey">Balance</p>
                {isLoadingBalance ? (
                  <div className="wallet-preloader">
                    <img
                      src={casperWalletPreloader}
                      alt=""
                      className="wallet-"
                    />
                  </div>
                ) : (
                  <div className="connected-block--green">
                    {walletBalance
                      ? sumFormat(walletBalance / 1000000000)
                      : null}{" "}
                    CSPR
                  </div>
                )}
              </div>
              <div
                className="connected-block__update"
                onClick={isLoadingBalance ? null : () => handleBalance()}
                onKeyDown={isLoadingBalance ? null : () => handleBalance()}
                role="button"
                tabIndex={0}
              >
                <img src={casperWalletUpdate} alt="" />
                <p>Update</p>
              </div>
            </div>
          </>
        ) : null}
        {/* Casper Wallet Panel /\/\/\//\/*/}
        {walletType === "CasperWallet" ? (
          <>
            <div className="wallet-panel__header">
              <img
                src={casperWalletIcon}
                className="wallet-panel__header-icon"
                alt=""
              />
              <p>{truncateto5symbols(activePublicKey)}</p>
              <img className="header-arrow" src={arrowUpIcon} alt="" />
            </div>
            <div className="wallet-panel-hover--connected">
              <div
                className="connected-block--grey"
                onClick={handleDisconnect}
                onKeyDown={handleDisconnect}
                tabIndex={0}
                role="button"
              >
                <img
                  alt=""
                  src={casperWalletDisconnect}
                  title="Disconnect Casper Wallet"
                />
                <p>Disconnect</p>
              </div>
              <div
                className="connected-block--grey"
                role="button"
                tabIndex={0}
                onClick={() => displayWalletInfo()}
                onKeyDown={() => displayWalletInfo()}
              >
                <img src={iconWalletAccount} alt="" />
                <p>View Account Info</p>
              </div>
              <div
                className="connected-block--grey"
                title={activePublicKey}
                onClick={() => handleCopyClick(activePublicKey)}
                onKeyDown={() => handleCopyClick(activePublicKey)}
                role="presentation"
              >
                {" "}
                <div className="wallet-iconbuffercopy">
                  <img
                    src={isCopied ? iconBufferCopied : iconBufferCopy}
                    alt=""
                    title="Copy account number"
                  />
                </div>
                Copy Public Key
              </div>
              <div className="connected-block__balance">
                <p className="connected-block__balance--grey">Balance</p>
                {isLoadingBalance ? (
                  <div className="wallet-preloader">
                    <img
                      src={casperWalletPreloader}
                      alt=""
                      className="wallet-"
                    />
                  </div>
                ) : (
                  <div className="connected-block--green">
                    {walletBalance
                      ? sumFormat(walletBalance / 1000000000)
                      : null}{" "}
                    CSPR
                  </div>
                )}
              </div>
              <div
                className="connected-block__update"
                onClick={isLoadingBalance ? null : () => handleBalance()}
                onKeyDown={isLoadingBalance ? null : () => handleBalance()}
                role="button"
                tabIndex={0}
              >
                <img src={casperWalletUpdate} alt="" />
                <p>Update</p>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

WalletPanel.propTypes = {
  walletType: PropTypes.string,
  setWalletType: PropTypes.func.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  setTabActive: PropTypes.func.isRequired,
}
