import { buildData, buildLogo } from "./utils"
import { getFolder } from "./utils"

const owner = process.env.GATSBY_GITHUB_OWNER
const repo = process.env.GATSBY_GITHUB_REPO
const token = process.env.GATSBY_GITHUB_TOKEN

// GET the sha of the last commit in "Main" branch
export async function getMainBranch() {
  return fetch(`https://api.github.com/repos/${owner}/${repo}/commits/main`)
}

// Create a Branch with the projectId name
export async function createBranch(sha, name) {
  const projectId = name.replace(/[\W_]+/g, "_").toLowerCase()
  return fetch(`https://api.github.com/repos/${owner}/${repo}/git/refs`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ref: "refs/heads/" + projectId,
      sha: sha,
    }),
  })
}

// Create the Data Commit
export async function createCommitData(projectId, form, type, repoType, sha) {
  const content = btoa(buildData(form, type, repoType))

  const requestBody = {
    message: sha
      ? `Update data.md of ${projectId}`
      : `Add data.md of ${projectId}`,
    committer: {
      name: "Casperecosystem",
      email: "casper@casper.com",
    },
    branch: projectId,
    content: content,
  }

  if (sha) {
    requestBody.sha = sha
  }
  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/contents/${getFolder(
      type
    )}/${projectId}/data.md`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  )
}

// Create a Commit
export async function createCommitLogo(projectId, image, type, sha) {
  const logo = await buildLogo(image)
  const requestBody = {
    message: sha
      ? `Update logo.png of ${projectId}`
      : `Add logo.png of ${projectId}`,
    committer: {
      name: "Casperecosystem",
      email: "casper@casper.com",
    },
    branch: projectId,
    content: logo.split("data:image/png;base64,")[1],
  }

  if (sha) {
    requestBody.sha = sha
  }
  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/contents/${getFolder(
      type
    )}/${projectId}/assets/logo.png`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  )
}

// Create the PR
export async function createPR(projectId, type, sha) {
  return fetch(`https://api.github.com/repos/${owner}/${repo}/pulls`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: sha ? `Update ${projectId} ${type}` : `Add ${projectId} ${type}`,
      body: `Please pull this awesome ${type} in!`,
      head: projectId,
      base: "main",
    }),
  })
}

// Add Reviewer to the PR
export async function reviewPR(pullNumber) {
  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/pulls/${pullNumber}/requested_reviewers`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reviewers: [
          "devendran-m",
          "piotr-dziubecki",
          "Marjan-sha",
          "Adrian-Wrona",
        ],
      }),
    }
  )
}

// Get Service Provider information
export async function getProviderData(provider) {
  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/contents/service-providers/${provider}/data.md`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  )
}
// Get Project information
export async function getProjectData(name, type) {
  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/contents/${type}/${name
      .toLowerCase()
      .replaceAll(" ", "_")}/data.md`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  )
}
// Get Project logo
export async function getProjectLogo(name, type) {
  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/contents/${type}/${name
      .toLowerCase()
      .replaceAll(" ", "_")}/assets/logo.png`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  )
}

// Add project to Service Provider data.md
export async function updateProviderData(branch, sha, provider, providerData) {
  const encoder = new TextEncoder()
  const data = encoder.encode(providerData)
  const content = btoa(String.fromCharCode.apply(null, data))

  return fetch(
    `https://api.github.com/repos/${owner}/${repo}/contents/service-providers/${provider}/data.md`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: `Add project to data.md of ${provider}`,
        committer: {
          name: "Casperecosystem",
          email: "casper@casper.com",
        },
        branch: branch,
        content: content,
        sha: sha,
      }),
    }
  )
}

// Fetch Commit SHA information from Github
export async function getSHA() {
  return await (
    await fetch(`https://api.github.com/repos/${owner}/${repo}/commits/main`, {
      method: "GET",
      headers: {
        Authorization: token ? "Bearer " + token : null,
      },
    })
  ).json()
}

// Fetch Commit Tags information from Github
export async function getTags() {
  return await (
    await fetch(`https://api.github.com/repos/${owner}/${repo}/tags`, {
      method: "GET",
      headers: {
        Authorization: token ? "Bearer " + token : null,
      },
    })
  ).json()
}

// Fetch Repository Chart Information from Github
export async function getRepoChart(ownerRepo) {
  const owner = ownerRepo.split("/")[0]
  const repo = ownerRepo.split("/")[1]
  // Get the chart info from the repository
  let repoChart = {}

  await new Promise(async resolve => {
    // Many times the response is 202 but no data is returned, so we loop until response is rejected or approved with content
    while (true) {
      var response = await fetch(
        `https://api.github.com/repos/${owner}/${repo}/stats/commit_activity`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      if (response.status !== 202) {
        repoChart = await response.json()
        resolve()
        break
      }
    }
  })
  return repoChart
}

// Fetch Repository GraphQL Information from Github
export async function getRepository(ownerRepo) {
  const owner = ownerRepo.split("/")[0]
  const repo = ownerRepo.split("/")[1]
  return await (
    await fetch("https://api.github.com/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        query: `
          query {
            repository(owner: "${owner}", name:"${repo}") {
              name
    languages(first: 10, orderBy: {field: SIZE, direction: DESC}) {
      nodes {
        name
        color
      }
    }
    stargazers {
      totalCount
    }
    licenseInfo {
      name
    }
    assignableUsers {
      totalCount
    }
    forkCount
    issues(states: OPEN) {
      totalCount
    }
    pullRequests(states: OPEN) {
      totalCount
    }
    watchers {
      totalCount
    }
    branches: refs(refPrefix: "refs/heads/", first: 1) {
      totalCount
    }
    pushedAt
    description
    repositoryTopics(first: 100) {
      nodes {
        topic {
          name
        }
      }
    }
  }
}
        `,
      }),
    })
  ).json()
}

// Fetch Grant PM information from Github
export async function getPM(namePM) {
  return await (
    await fetch(`https://api.github.com/users/${namePM}`, {
      method: "GET",
      headers: {
        Authorization: token ? "Bearer " + token : null,
      },
    })
  ).json()
}

// Fetch Repository GraphQL Information from Github
export async function getTopics(repositories) {
  let topicsArray = []
  let topicsObject = []
  await Promise.all(
    repositories.map(async repository => {
      let repoData = await getRepository(
        repository.repo.split("https://github.com/")[1]
      )
      if (repoData.data.repository) {
        topicsArray.push(
          ...repoData.data.repository.repositoryTopics.nodes.map(
            t => t.topic.name
          )
        )
        topicsObject.push({
          name: repository.name,
          topics: repoData.data.repository.repositoryTopics.nodes.map(
            t => t.topic.name
          ),
        })
      }
    })
  )
  return { topics: [...new Set(topicsArray)], topicsObject }
}
