import React from "react"
import PropTypes from "prop-types"

import { useWalletService } from "../casper-wallet/wallet-service"
import { CasperDashContext } from "../Layout"

import iconCancelCross from "../../assets/wallet-select-close-icon.svg"
import casperDashIcon from "../../assets/casperdash-icon.svg"
import casperWalletIcon from "../../assets/casperwallet-icon.svg"

function WalletSelect({ setIsSelectVisible, setWalletType, setWalletError }) {
  const { connect } = useWalletService()
  const { isCasperDashInstalled } = React.useContext(CasperDashContext)

  const handleCasperWalletConnect = () => {
    connect().catch(error => {
      console.log(error)
      setWalletError("Casper Wallet")
    })
  }

  const handleWalletChoice = wallet => {
    if (wallet === "CasperWallet") {
      handleCasperWalletConnect()
      setWalletType(wallet)
    }
    if (wallet === "CasperDashWallet") {
      if (isCasperDashInstalled) {
        window.casperDashHelper.requestConnection()
      } else {
        setWalletError("Casper Dash Wallet")
      }
    }
    setIsSelectVisible(false)
  }

  return (
    <>
      <div
        className="wallet-select-overlay"
        onClick={() => setIsSelectVisible(false)}
        onKeyDown={() => setIsSelectVisible(false)}
        role="button"
        tabIndex={0}
        aria-label="Wallet Select Panel Overlay"
      />
      <div className="wallet-select">
        <div className="wallet-select__topblock">
          <div />
          <div
            className="wallet-select__closebutton"
            onClick={() => setIsSelectVisible(false)}
            onKeyDown={() => setIsSelectVisible(false)}
            tabIndex={0}
            role="button"
          >
            <img src={iconCancelCross} alt="" />
          </div>
        </div>
        <div className="wallet-select__midblock">
          <h3 className="wallet-select__title">Sign in</h3>
          <p className="wallet-select__sign-text">
            to continue to{" "}
            <span className="wallet-select__sign-text--white">
              Casper Ecosystem
            </span>
          </p>
        </div>
        <div className="wallet-select__bottomblock">
          <button
            className="wallet-select__button"
            onClick={() => handleWalletChoice("CasperDashWallet")}
          >
            <img
              className="wallet-select__button-icon"
              src={casperDashIcon}
              alt=""
            />
            <p className="wallet-select__button-text">CasperDash</p>
          </button>
          <button
            className="wallet-select__button"
            onClick={() => handleWalletChoice("CasperWallet")}
          >
            <img
              className="wallet-select__button-icon"
              src={casperWalletIcon}
              alt=""
            />
            <p className="wallet-select__button-text">CasperWallet</p>
          </button>
        </div>
      </div>
    </>
  )
}

WalletSelect.propTypes = {
  setIsSelectVisible: PropTypes.func.isRequired,
  setWalletType: PropTypes.func.isRequired,
  setWalletError: PropTypes.func.isRequired,
}

export default WalletSelect
