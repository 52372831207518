import React from "react"
import PropTypes from "prop-types"
import iconCancelCross from "../../assets/icon-cancel-cross.svg"

function WalletError({ setWalletError, walletError }) {
  return (
    <>
      <div
        className="error-popup-overlay"
        onClick={() => setWalletError("")}
        onKeyDown={() => setWalletError("")}
        role="presentation"
      />
      <div className="error-popup">
        <div className="error-popup__topblock">
          <div />
          <img
            src={iconCancelCross}
            alt=""
            onClick={() => setWalletError("")}
            onKeyDown={() => setWalletError("")}
            className="close-cross"
            role="presentation"
          />
        </div>
        <div className="error-popup__bottomblock">
          <h3>Error!</h3>
          <p>
            Please install the{" "}
            <a
              className="error-popup__link"
              href={
                walletError === "Casper Wallet"
                  ? "https://www.casperwallet.io/"
                  : "https://casperdash.io/"
              }
              target="_blank"
              rel="noreferrer"
            >
              {walletError}
            </a>{" "}
            Extension <br />
            and refresh the page
          </p>
          <div className="error-popup__button">
            <button
              className="btn btn--primary"
              onClick={() => setWalletError("")}
              onKeyDown={() => setWalletError("")}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

WalletError.propTypes = {
  setWalletError: PropTypes.func.isRequired,
  walletError: PropTypes.string.isRequired,
}

export default WalletError
