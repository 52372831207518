exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casper-ecosystem-landscape-js": () => import("./../../../src/pages/casper-ecosystem-landscape.js" /* webpackChunkName: "component---src-pages-casper-ecosystem-landscape-js" */),
  "component---src-pages-check-verification-status-js": () => import("./../../../src/pages/check-verification-status.js" /* webpackChunkName: "component---src-pages-check-verification-status-js" */),
  "component---src-pages-ecosystem-project-js": () => import("./../../../src/pages/ecosystem/project.js" /* webpackChunkName: "component---src-pages-ecosystem-project-js" */),
  "component---src-pages-grants-grant-js": () => import("./../../../src/pages/grants/grant.js" /* webpackChunkName: "component---src-pages-grants-grant-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrated-intake-form-js": () => import("./../../../src/pages/integrated-intake-form.js" /* webpackChunkName: "component---src-pages-integrated-intake-form-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-providers-js": () => import("./../../../src/pages/providers.js" /* webpackChunkName: "component---src-pages-providers-js" */),
  "component---src-pages-providers-providers-id-js": () => import("./../../../src/pages/providers/{Providers.id}.js" /* webpackChunkName: "component---src-pages-providers-providers-id-js" */),
  "component---src-pages-repositories-js": () => import("./../../../src/pages/repositories.js" /* webpackChunkName: "component---src-pages-repositories-js" */),
  "component---src-pages-repositories-repositories-id-js": () => import("./../../../src/pages/repositories/{Repositories.id}.js" /* webpackChunkName: "component---src-pages-repositories-repositories-id-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-verify-my-contract-js": () => import("./../../../src/pages/verify-my-contract.js" /* webpackChunkName: "component---src-pages-verify-my-contract-js" */)
}

