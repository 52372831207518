import React, { useState, useEffect } from "react"
import { Link } from "gatsby-plugin-react-i18next"
import Navbar from "./organisms/navbar"
import Footer from "./organisms/footer"
import CookieConsent from "react-cookie-consent"
import useWindowDimensions from "../hooks/useWindowDimensions"

import disclaimerWarning from "../assets/disclaimer-warning.svg"
import arrowUp from "../assets/arrow-up-small-black.svg"
import arrowDown from "../assets/arrow-down-small-black.svg"
import { WalletServiceProvider } from "./casper-wallet/wallet-service"
export const LayoutContext = React.createContext()
export const CasperDashContext = React.createContext()
export const TabContext = React.createContext()

const Layout = ({ children }) => {
  const { width } = useWindowDimensions()
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false)
  const [isDisclaimerTextHidden, setIsDisclaimerTextHidden] = useState(true)
  const [casperDashPublicKey, setCasperDashPublicKey] = useState(null)
  const [isCasperDashInstalled, setIsCasperDashInstalled] = useState(false)
  const [walletType, setWalletType] = useState("")
  const [tabActive, setTabActive] = useState("Stats")
  const [selectedOption, setSelectedOption] = useState("my-wallet")

  //Check Casper Dash instalation
  const checkIsDashInstalled = () => {
    if (typeof window !== "undefined" && window.casperDashHelper) {
      setIsCasperDashInstalled(true)
    }
  }

  //Casper Dash connection & disconnection listener
  if (typeof window !== "undefined") {
    window.addEventListener("casperdash:connected", event => {
      setCasperDashPublicKey(event.detail?.activeKey)
      localStorage.setItem("walletType", "CasperDashWallet")
      // 1. when logged in, after page refresh, Casper Dash key can switch to null (for unknown reason)
      // here we store the key in SS to restore it after page refresh
      localStorage.setItem(
        "savedDashKey",
        JSON.stringify(event.detail?.activeKey)
      )
      setWalletType("CasperDashWallet")
    })
  }

  if (typeof window !== "undefined") {
    window.addEventListener("casperdash:disconnected", () => {
      setCasperDashPublicKey(null)
      localStorage.setItem("walletInfo", null)
      localStorage.setItem("walletType", null)
      // 2. when Dash Wallet disconnected, we clean SS for Casper Dash key
      localStorage.setItem("savedDashKey", null)
    })
  }

  useEffect(() => {
    checkIsDashInstalled()
    // 2. for every page refresh, we check for saved Dash Key in SS
    // if the key is there, it means that session is not closed
    const savedPublicKey = JSON.parse(localStorage.getItem("savedDashKey"))
    setCasperDashPublicKey(savedPublicKey)
  }, [])

  const disclaimerTextContent = () => {
    return (
      <>
        <div>
          The information provided about the Project, Provider, and Repositories
          is provided by their respective owners and has not been verified by
          the Casper Association. We do not make any warranties regarding
          completeness, reliability, or accuracy.
        </div>
        {isDisclaimerTextHidden ? null : (
          <>
            <div>
              Any decisions you make based on the information on our website are
              at your own risk, and we will not be liable for any losses or
              damages. We also cannot be held responsible for the success or
              failure of projects.
            </div>
            <div>
              Please note that being listed on our website should not be
              considered an endorsement by us or any affiliated parties. We
              encourage you to conduct your own research and analysis before
              making any decisions based on the information provided by the
              respective owners.
            </div>
          </>
        )}
      </>
    )
  }

  const getCookie = cname => {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  if (!width) return null

  return (
    <WalletServiceProvider>
      <div className="App">
        {isDisclaimerOpened || getCookie("disclaimer") ? (
          <CookieConsent
            disableStyles={true}
            location={false}
            buttonText="Ok"
            cookieName="disclaimer"
            buttonWrapperClasses="popup-disclaimer__button-wrapper"
            buttonClasses="btn btn--primary cookies__button cookies__button--disclaimer"
            containerClasses="popup-disclaimer"
            overlay={true}
            overlayClasses="cookies-popups-overlay"
          >
            <div className="popup-disclaimer__wrapper">
              <div className="popup-disclaimer__leftblock">
                <img src={disclaimerWarning} alt="" />
              </div>
              <div className="popup-disclaimer__rightblock">
                <div className="popup-disclaimer__title">Remember</div>
                <div className="popup-disclaimer__subtitle">
                  Do Your Own Research
                </div>
                <div className="popup-disclaimer__text">
                  {disclaimerTextContent()}
                </div>
                <div className="popup-disclaimer__button-container">
                  {isDisclaimerTextHidden ? (
                    <>
                      <button
                        className="popup-disclaimer__button"
                        onClick={() => setIsDisclaimerTextHidden(false)}
                      >
                        More
                      </button>
                      <img src={arrowUp} alt="" />
                    </>
                  ) : (
                    <>
                      <button
                        className="popup-disclaimer__button"
                        onClick={() => setIsDisclaimerTextHidden(true)}
                      >
                        Less
                      </button>
                      <img src={arrowDown} alt="" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </CookieConsent>
        ) : null}
        <CookieConsent
          disableStyles={true}
          enableDeclineButton
          location={false}
          buttonText="I Agree"
          declineButtonText="Reject"
          cookieName="gatsby-gdpr-google-analytics"
          buttonWrapperClasses="cookies__btns"
          buttonClasses="btn btn--primary cookies__button"
          declineButtonClasses="btn cookies__button cookies__button--dismiss"
          containerClasses="cookies"
          overlay={true}
          overlayClasses="cookies-popups-overlay"
        >
          <div className="cookies__column">
            <div className="cookies__title">This website uses cookies</div>
            <div className="cookies__text">
              By continuing to browse the site, you are agreeing to our use of
              cookies. We use cookies to improve user experience. For more
              details please see our{" "}
              <Link to="/privacy-policy">
                <div className="cookies__link">Privacy policy</div>
              </Link>
            </div>
          </div>
        </CookieConsent>
        <TabContext.Provider
          value={{ tabActive, setTabActive, selectedOption, setSelectedOption }}
        >
          <CasperDashContext.Provider
            value={{
              casperDashPublicKeyContext: [
                casperDashPublicKey,
                setCasperDashPublicKey,
              ],
              isCasperDashInstalled,
            }}
          >
            <Navbar
              setWalletType={setWalletType}
              walletType={walletType}
              tabActive={tabActive}
              setTabActive={setTabActive}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <LayoutContext.Provider value={setIsDisclaimerOpened}>
              {children}
            </LayoutContext.Provider>
          </CasperDashContext.Provider>
        </TabContext.Provider>
        <Footer />
      </div>
    </WalletServiceProvider>
  )
}

export default Layout
