import React from "react"

// Packages for translations
import { Trans } from "gatsby-plugin-react-i18next"

// Function to get version of the repo from Github
import { getSHA, getTags } from "../../utils/ghInteractions"

import BtnFooter from "../atoms/btn-footer"

// Icon and Logos
import heartFeed from "../../assets/heart-feed.svg"
import casperLogo from "../../assets/casper-association-logo.svg"
import blockbit from "../../assets/blockbites-logo-grey.svg"
import twitterIcon from "../../assets/twitter-foooter-icon.svg"

import { useQuery } from "@tanstack/react-query"

const Footer = () => {
  const repoSHA = useQuery(
    ["getSHA"],
    async () => {
      return getSHA()
    },
    { staleTime: Infinity }
  )
  const repoTags = useQuery(
    ["getTags"],
    async () => {
      return getTags()
    },
    { staleTime: Infinity }
  )

  return (
    <div className="footer">
      <div className="main-padding positioner positioner--column footer__wrapper">
        <div className="footer__content">
          <div className="footer__content-leftblock">
            <a
              href="//casper.network/en/network"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={casperLogo}
                alt="casper-logo"
                loading="lazy"
                className="footer__casper-logo"
              />
            </a>
            <div className="footer__content-social">
              <p>Social Media </p>
              <a
                href="https://twitter.com/casperecosystem"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={twitterIcon}
                  title="CasperEcosystem.io Twitter / X"
                  className="footer__content-ximage"
                  alt="X / Twitter"
                />
              </a>
            </div>
          </div>
          <div className="footer__explore">
            <div className="h5 footer__h5">
              <Trans>Explore</Trans>
            </div>
            <div className="footer__btns">
              <BtnFooter internal text="Casper Ecosystem" url="/#ecosystem" />
              <BtnFooter
                text="Build on Casper"
                url="https://docs.casper.network/"
              />
              <BtnFooter internal text="Privacy Policy" url="/privacy-policy" />
              <BtnFooter
                text="Casper Association"
                url="https://casper.network/en-us/"
              />
              <BtnFooter
                text="Terms of Use"
                url="https://casper.network/en-us/terms-of-use"
              />
            </div>
          </div>
          <div>
            <div className="h5 footer__h5">FEEDBACK</div>
            <div className="footer__contFeed">
              <div className="footer__textFeed">
                Let us know what can we improve, your opinion matters!
              </div>
              <div className="footer__button">
                <a
                  href={"https://casperecosystem.canny.io/"}
                  target="_blank"
                  className="btn btn--feedback"
                  rel="noreferrer"
                >
                  <Trans>Feedback</Trans>
                  <img src={heartFeed} alt="feedback" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__copyright">
            © {new Date().getFullYear()} Casper Association
          </div>
          <div className="footer__copyright">
            {repoSHA.isSuccess && repoTags.isSuccess
              ? `${repoTags.data[0] || "v1.5.2"} (${
                  repoSHA.data.sha?.slice(0, 7) || "XXXXXXX"
                })`
              : ""}
          </div>
          <div className="footer__blocklink">
            Made by
            <a href="https://blockbit.es" target="_blank" rel="noreferrer">
              <img
                src={blockbit}
                alt="blockbit.es"
                className="footer__blockbit"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
