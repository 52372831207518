import React, { useState, useEffect } from "react"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"

import NavDropdownMenuMobile from "../atoms/nav-dropdown-menu-mobile"

import MenuIcon from "../../assets/menu_lines.svg"
import closeIcon from "../../assets/close-icon.svg"
import arrowUpIcon from "../../assets/arrow-up-navbar-mobile.svg"

const NavbarMobile = ({ toggleMenu, closeMenu, menu }) => {
  const [isExploreVisible, setIsExploreVisible] = useState(false)
  const [isVerifierVisible, setIsVerifierVisible] = useState(false)

  const location = useLocation()

  useEffect(() => {
    if (
      location.pathname === "/check-verification-status/" ||
      location.pathname === "/verify-my-contract/"
    ) {
      setIsVerifierVisible(true)
    } else setIsVerifierVisible(false)

    if (
      location.pathname === "/providers/" ||
      location.pathname === "/" ||
      location.pathname === "/casper-ecosystem-landscape/"
    ) {
      setIsExploreVisible(true)
    } else setIsExploreVisible(false)
  }, [location.pathname])

  return (
    <>
      {/* {process.env.GATSBY_FEATURE_MULTILANG === "1" ? (
      <div className="navbar__dropdown-lang">
        <button className="btn btn--lang" onClick={toggleMenuLang}>
          {context.language}
          <img
            src={arrowDown}
            alt=""
            className="navbar__arrow"
          />
        </button>
        {menuLang ? (
          <div className="navbar__dropdown-lang-content" ref={ref}>
            {languages.map(lng => (
              <button
                className={"btn btn--lang "}
                onClick={e => {
                  e.preventDefault()
                  changeLanguage(lng)
                }}
              >
                {lng}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    ) : null} */}
      <div className="navbar-dropdown">
        <img src={MenuIcon} alt="" onClick={toggleMenu} role="presentation" />
        {menu ? (
          <div className="navbar-dropdown__content">
            <div
              className="navbar-dropdown__close"
              onClick={toggleMenu}
              onKeyDown={toggleMenu}
              role="presentation"
            >
              <img src={closeIcon} alt="close" />
            </div>
            <div className="navbar-dropdown__btns-container">
              <div className="explore-menu-mobile__wrapper">
                <div
                  className={
                    "btn btn--nohover navbar-dropdown__btn " +
                    (location.pathname === "/" ||
                    location.pathname === "/casper-ecosystem-landscape/" ||
                    location.pathname === "/providers/"
                      ? "navbar-dropdown__btn--active"
                      : "")
                  }
                  onClick={() => setIsExploreVisible(prev => !prev)}
                  onKeyDown={() => setIsExploreVisible(prev => !prev)}
                  role="presentation"
                >
                  <Trans>Explore</Trans>{" "}
                  <img
                    src={arrowUpIcon}
                    alt=""
                    className={
                      "navbar-mobile-arrow " +
                      (isExploreVisible ? "rotated" : "")
                    }
                  />
                </div>
                <NavDropdownMenuMobile
                  active={isExploreVisible}
                  onClick={closeMenu}
                  location={location.pathname}
                />
              </div>
              <Link
                className={
                  "btn btn--teritary navbar-dropdown__btn " +
                  (location.pathname === "/repositories/"
                    ? "navbar-dropdown__btn--active"
                    : "")
                }
                to="/repositories"
                onClick={closeMenu}
                onKeyDown={closeMenu}
                tabIndex={0}
              >
                <Trans>Repositories</Trans>
              </Link>
              <Link
                className={
                  "btn btn--teritary navbar-dropdown__btn " +
                  (location.pathname === "/grants/"
                    ? "navbar-dropdown__btn--active"
                    : "")
                }
                to="/grants"
                onClick={closeMenu}
                onKeyDown={closeMenu}
                tabIndex={0}
              >
                <Trans>Grants</Trans>
              </Link>
              <Link
                className={
                  "btn btn--teritary navbar-dropdown__btn " +
                  (location.pathname === "/stats/"
                    ? "navbar-dropdown__btn--active"
                    : "")
                }
                to="/stats"
                onClick={closeMenu}
                onKeyDown={closeMenu}
                tabIndex={0}
              >
                <Trans>Blockchain Stats</Trans>
              </Link>
              <div className="explore-menu-mobile__wrapper">
                <div
                  className={
                    "btn btn--nohover navbar-dropdown__btn " +
                    (location.pathname === "/check-verification-status/" ||
                    location.pathname === "/verify-my-contract/"
                      ? "navbar-dropdown__btn--active"
                      : "")
                  }
                  onClick={() => setIsVerifierVisible(prev => !prev)}
                  onKeyDown={() => setIsVerifierVisible(prev => !prev)}
                  role="presentation"
                >
                  <Trans>Source Code Verifier</Trans>{" "}
                  <img
                    src={arrowUpIcon}
                    alt=""
                    className={
                      "navbar-mobile-arrow " +
                      (isVerifierVisible ? "rotated" : "")
                    }
                  />
                </div>
                <NavDropdownMenuMobile
                  active={isVerifierVisible}
                  onClick={closeMenu}
                  location={location.pathname}
                  type="verifier"
                />
              </div>
            </div>
            {/* <div className="navbar__btns">
            {process.env.GATSBY_FEATURE_ACCOUNT === "1" ? (
            <Link to="/" className="btn btn--sign">
              <Trans>Log in</Trans>
            </Link>
            ) : null}
          </div> */}
          </div>
        ) : null}
      </div>
    </>
  )
}

NavbarMobile.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  menu: PropTypes.bool.isRequired,
}

export default NavbarMobile
