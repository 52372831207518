import React from "react"
import PropTypes from "prop-types"
import BtnFooter from "./btn-footer" // we can use BtnFooter here (but I leave one block commented just in case)

export default function NavDropdownMenu({ type }) {
  // "Explore" menu is returning by default
  switch (type) {
    case "verifier":
      return (
        <div className="explore-menu">
          <div className="explore-menu__top explore-menu__top--no-border">
            <BtnFooter
              internal
              text="Check Verification Status"
              url="/check-verification-status"
            />
          </div>
          <div className="explore-menu__bottom">
            <BtnFooter
              internal
              text="Verify My Contract"
              url="/verify-my-contract"
            />
          </div>
        </div>
      )
    default:
      return (
        <div className="explore-menu">
          <div className="explore-menu__top">
            <BtnFooter internal text="Ecosystem" url="/#ecosystem" />
          </div>
          <div className="explore-menu__top">
            <BtnFooter
              internal
              text="Landscape"
              url="/casper-ecosystem-landscape"
            />
          </div>
          <div className="explore-menu__bottom">
            <BtnFooter internal text="Service Providers" url="/providers" />
          </div>
        </div>
      )
  }
}

NavDropdownMenu.propTypes = {
  type: PropTypes.string,
}
