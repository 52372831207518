import React from "react"
import PropTypes from "prop-types"
import { Trans, Link } from "gatsby-plugin-react-i18next"

import ArrowSrc from "../../assets/arrow.svg"

const BtnFooter = ({ text, url, internal, isActive }) => {
  return (
    <>
      {internal ? (
        <Link to={url} className="btn btn-footer" rel="noreferrer">
          <img src={ArrowSrc} alt="arrow" className="btn-footer__arrow" />
          <p
            className={
              "btn-footer__text" +
              (isActive ? " navbar-dropdown__btn--active" : "")
            }
          >
            <Trans>{text}</Trans>
          </p>
        </Link>
      ) : (
        <a
          href={url}
          className="btn btn-footer"
          target="_blank"
          rel="noreferrer"
        >
          <img src={ArrowSrc} alt="arrow" className="btn-footer__arrow" />
          <p className="btn-footer__text">
            <Trans>{text}</Trans>
          </p>
        </a>
      )}
    </>
  )
}

BtnFooter.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  internal: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default BtnFooter
