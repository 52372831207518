import React from "react"
import PropTypes from "prop-types"

import BtnFooter from "./btn-footer"

export default function NavDropdownMenuMobile({
  active,
  onClick,
  location,
  type,
}) {
  switch (type) {
    case "verifier":
      return (
        <div
          className={"explore-menu-mobile " + (active ? "visible" : "")}
          onClick={onClick}
          role="presentation"
        >
          <div className="explore-menu-mobile__top">
            <BtnFooter
              isActive={location === "/check-verification-status/"}
              internal
              text="Check Verification Status"
              url="/check-verification-status"
            />
          </div>
          <div className="explore-menu-mobile__bottom">
            <BtnFooter
              isActive={location === "/verify-my-contract/"}
              internal
              text="Verify My Contract"
              url="/verify-my-contract"
            />
          </div>
        </div>
      )
    default:
      return (
        <div
          className={"explore-menu-mobile " + (active ? "visible" : "")}
          onClick={onClick}
          role="presentation"
        >
          <div className="explore-menu-mobile__top">
            <BtnFooter
              isActive={location === "/"}
              internal
              text="Ecosystem"
              url="/#ecosystem"
            />
          </div>
          <div className="explore-menu-mobile__top">
            <BtnFooter
              isActive={location === "/casper-ecosystem-landscape/"}
              internal
              text="Landscape"
              url="/casper-ecosystem-landscape"
            />
          </div>
          <div className="explore-menu-mobile__bottom">
            <BtnFooter
              isActive={location === "/providers/"}
              internal
              text="Service Providers"
              url="/providers"
            />
          </div>
        </div>
      )
  }
}
NavDropdownMenuMobile.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  location: PropTypes.string,
  type: PropTypes.string,
}
