import React from "react"

export const convertIdToUrl = id => {
  return id
    .replaceAll("_", "-")
    .replaceAll(" ", "-")
    .replaceAll(".", "")
    .replace(/-$/, "")
    .toLowerCase()
}
export const getDescription = (description, language) => {
  return description
    .split(`<!--lang:${language}-->`)[1]
    .split("<!--lang:")[0]
    .trim()
}
export const getFolder = type => {
  const folderMap = {
    Provider: "service-providers",
    Project: "ecosystem",
    Repository: "repositories",
  }

  return folderMap[type] || ""
}

const setProjectStatus = form => {
  if (form.MainNetContractHash !== "") {
    return form.Status + " Mainnet"
  } else if (
    form.TestNetContractHash !== "" &&
    form.MainNetContractHash === ""
  ) {
    return form.Status + " Testnet"
  } else {
    return form.Status
  }
}

export const buildData = (form, type, repo) => {
  let projectTag = ""
  let projectArchitecture = ""

  if (type === "Project") {
    const segment = form.Segment.Segment

    // Set the Tag depending on the Segment Values
    if (form.Segment.Category === "Wallet") {
      projectTag = "Wallets"
    } else if (
      segment === "Indexers" ||
      segment === "Smart Contracts" ||
      segment === "Scalability" ||
      segment === "I - Other" ||
      segment === "Node as a Service" ||
      segment === "Contributors" ||
      segment === "Other DApps"
    ) {
      projectTag = "DApps"
    } else if (segment === "Consultancy" || segment === "P-Other") {
      projectTag = "Partner"
    } else {
      projectTag = segment
    }

    // Set the architecture depending on the Segment Values
    if (segment === "Learn & Earn" || segment === "Casper Web3 Onboarder") {
      projectArchitecture = "L2 Education"
    } else if (
      segment === "Exchanges" ||
      segment === "Validators" ||
      segment === "Node as a Service" ||
      segment === "Contributors"
    ) {
      projectArchitecture = "L1 Operators"
    } else if (
      segment === "Software House" ||
      segment === "Creative Agency" ||
      segment === "Marketing Agency" ||
      segment === "Consultancy" ||
      segment === "P-Other"
    ) {
      projectArchitecture = "Partners"
    } else if (
      segment === "NFT" ||
      segment === "Gaming" ||
      segment === "CeFi" ||
      segment === "DeFi" ||
      segment === "Other DApps" ||
      segment === "DAO"
    ) {
      projectArchitecture = "User & Access Layer"
    } else {
      projectArchitecture = "Infrastructure"
    }
  }
  switch (type) {
    case "Provider":
      return `--- 
Name: "${form.Name || ""}", 
Website: "${form.Website || ""}", 
OrgSize: "${form.OrganizationSize || ""}",
Specializations: "${form.Specialization || ""}", 
POC: "${form.OrganizationContact || ""}", 
Projects: "${form.CasperBlockchainProjects || ""}", 
Address: "${form.Address || ""}", 
Twitter: "${form.Twitter ? `https://twitter.com/${form.Twitter}` : ""}", 
Discord: "${form.Discord ? `https://discord.gg/${form.Discord}` : ""}",
Telegram: "${form.Telegram ? `https://t.me/${form.Telegram}` : ""}", 
Architecture: "${form.Architecture || ""}",  
Segment: "${form.Segment || ""}", 
Category: "${form.Category || ""}", 
Application-Category: "${form.AppCategory || ""}", 
Tags: "${form.ServiceOfferings || ""}",
--- 
<!--lang:en--> 
${form.Overview || ""}
<!--lang:es--] 

<!--lang:de--] 

<!--lang:fr--] 

<!--lang:pl--] 

<!--lang:uk--] 

[!--lang:*--> `
    case "Project":
      return `--- 
Name: "${form.Name || ""}", 
Website: "${form.Website || ""}", 
Twitter: "${form.Twitter ? `https://twitter.com/${form.Twitter}` : ""}",
Architecture: "${projectArchitecture || ""}",
Segment: "${form.Segment.Segment || ""}",
Category: "${form.Segment.Category || ""}",
Application-Category: "${form.Segment.Application || ""}",
Tags: "${projectTag || ""}",
Contact: "${form.Email || ""}",
Project-Status: "${setProjectStatus(form) || ""}",
Contract-Hashes-Main: "${form.MainNetContractHash || ""}",
Contract-Hashes-Test: "${form.TestNetContractHash || ""}",
--- 
<!--lang:en--> 
${form.Overview || ""}
<!--lang:es--] 

<!--lang:de--] 

<!--lang:fr--] 

<!--lang:pl--] 

<!--lang:uk--] 

[!--lang:*--> `
    case "Repository":
      return `--- 
Name: "${form.Repositories.Name + "_" + repo || ""}", 
Website: "https://github.com/${form.Repositories[repo]}", 
Projects: "${form.ParentProject || ""}",
--- 
<!--lang:en--> 
${form.Description || ""}
<!--lang:es--] 

<!--lang:de--] 

<!--lang:fr--] 

<!--lang:pl--] 

<!--lang:uk--] 

[!--lang:*--> `
    default:
      break
  }
}

export const buildLogo = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export async function getMainChart() {
  // Launch all the requests for every sprint in parallel
  const [responseData, responsePrices] = await Promise.all([
    fetch(process.env.GATSBY_URL_API + "/api/casper?days=data"),
    fetch(process.env.GATSBY_URL_API + "/api/casper?days=14"),
  ])
  const data = await responseData.json()
  const prices = await responsePrices.json()

  // Sort the response in date order
  return { data: data.info, prices: prices.info }
}

// function that takes long public key, and leaves 5 symbols on start and end
export const truncateto5symbols = inputString => {
  if (inputString.length <= 10) {
    return inputString
  }
  return `${inputString.slice(0, 5)}...${inputString.slice(-5)}`
}

export const shortenName = (string, maxLength) => {
  if (string?.length <= maxLength || string === null || string === undefined) {
    return string
  }

  const words = string.split(" ")
  let currentLength = 0
  const truncatedString = []
  for (const word of words) {
    if (currentLength + word.length + truncatedString.length > maxLength) {
      break
    }
    truncatedString.push(word)
    currentLength += word.length + 1
  }
  return truncatedString.join(" ")
}

export const textColor = (fee, stake, perf) => {
  if (perf === null || fee === 0 || stake < 3000) return "#6f707d" // grey
  if (fee === null || stake === null) return "#FF202E" // red
  if (fee > 10 || perf < 95 || stake < 850000 || stake > 115000000)
    return "#FF202E" // red
  if (
    (fee >= 5 && fee <= 10) ||
    (stake >= 850000 && stake <= 1000000) ||
    (stake >= 100000000 && stake <= 115000000) ||
    (perf >= 95 && perf <= 98)
  )
    return "#FFE500" // yellow
  if (fee < 5 && stake > 1000000 && stake < 100000000 && perf > 98)
    return "#3EDC64" // green
}

export const perfColor = perf => {
  if (perf === 0 || perf === null) {
    return "#6f707d" // grey
  }
  if (perf < 95) {
    return "#FF202E" // red
  }
  if (perf >= 95 && perf <= 98) {
    return "#FFE500" // yellow
  }
  if (perf > 98) {
    return "#3EDC64" // green
  }
}

export const feeColor = fee => {
  if (fee === 0) {
    return "#6f707d" // grey
  }
  if (fee > 10) {
    return "#FF202E" // red
  }
  if (fee >= 5 && fee <= 10) {
    return "#FFE500" // yellow
  }
  if (fee < 5) {
    return "#3EDC64" // green
  }
}

export const amountColor = stake => {
  if (stake < 850000 || stake > 115000000) {
    return "#FF202E" // red
  }
  if (
    (stake >= 850000 && stake <= 1000000) ||
    (stake >= 100000000 && stake <= 115000000)
  ) {
    return "#FFE500" // yellow}
  }
  if (stake > 1000000 && stake < 100000000) {
    return "#3EDC64" // green
  }
}

export const validatorColorCsv = (fee, stake, perf) => {
  if (perf === null || fee === 0 || stake < 3000) return "GREY"
  if (fee === null || stake === null) return "RED"
  if (fee > 10 || perf < 95 || stake < 850000 || stake > 115000000) return "RED"
  if (
    (fee >= 5 && fee <= 10) ||
    (stake >= 850000 && stake <= 1000000) ||
    (stake >= 100000000 && stake <= 115000000) ||
    (perf >= 95 && perf <= 98)
  )
    return "YELLOW"
  if (fee < 5 && stake > 1000000 && stake < 100000000 && perf > 98)
    return "GREEN"
}

export const valTooltipExplain = (fee, stake, perf) => {
  const validatorColor = textColor(fee, stake, perf)

  const hex2Text = color => {
    if (color === "#3EDC64") return "green"
    if (color === "#FFE500") return "yellow"
    if (color === "#FF202E") return "red"
    if (color === "#6f707d") return "grey"
    else return "grey"
  }

  const getColorExplanation = (color, fee, stake, perf) => {
    const concerns = []
    if (color === "#3EDC64") {
      // green color condition
      return "all its criteria are good"
    }
    if (color === "#FFE500") {
      // yellow color conditions
      if (fee >= 5 && fee <= 10) {
        concerns.push("its commission rate is between 5% and 10%")
      }
      if (perf >= 95 && perf <= 98) {
        concerns.push("its performance is between 95% and 98%")
      }
      if (stake >= 850000 && stake <= 1000000) {
        concerns.push("its CSPR stake is between 850K and 1M")
      }
      if (stake >= 100000000 && stake <= 115000000) {
        concerns.push("its CSPR stake is between 100M and 115M")
      }
      return `${concerns.join(", and ")}`
    }
    if (color === "#FF202E") {
      // red color conditions (here can be some arguments === null)
      if (fee === null) {
        concerns.push("there's no data about its commission rate")
      }
      if (fee > 10 && fee !== null) {
        concerns.push("its commission rate is more than 10%")
      }
      if (stake === null) {
        concerns.push("there's no data about its CSPR stake")
      }
      if (stake < 850000 && stake !== null) {
        concerns.push("its CSPR stake is less than 850K")
      }
      if (stake > 115000000) {
        concerns.push("its CSPR stake is more than 115M")
      }
      if (perf !== null && perf < 95) {
        concerns.push("performance is less than 95%")
      }
      return `${concerns.join(", and ")}`
    }
    if (color === "#6f707d") {
      // grey color conditions processing
      if (perf === null) {
        concerns.push("there's no data about its performance")
      } else if (fee === 0) {
        concerns.push("its commission fee is 0")
      } else if (stake < 3000) {
        concerns.push("its CSPR stake less than 3000")
      }
      return `${concerns.join(", and ")}`
    }
  }
  const colorExplanation = getColorExplanation(validatorColor, fee, stake, perf)

  return (
    <>
      This validator's name is colored{" "}
      <span style={{ color: `${textColor(fee, stake, perf)}` }}>
        {hex2Text(validatorColor)}
      </span>
      , because {colorExplanation}
    </>
  )
}

export const sumFormat = (n, options) => {
  options = options || {}
  const d = options.delimiter || ","
  const s = options.separator || "."
  n = parseFloat(n).toFixed(5).toString().split(".")
  if (n.length > 1) {
    n[1] = n[1].slice(0, 5)
  }
  n[0] = n[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + d)
  return n.join(s)
}

export function hexToRgb(hex) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
}

// converting server error status to human readable format
export function mapServerErrors(statusCode) {
  switch (statusCode) {
    case "400":
      return "Bad Request: The server did not understand the request."
    case "401":
      return "Unauthorized: Authentication is required and has failed or has not been provided."
    case "403":
      return "Forbidden: The server understood the request but refuses to authorize it."
    case "404":
      return "Not Found: The requested resource could not be found on the server."
    case "500":
      return "Internal Server Error: The server encountered an unexpected condition that prevented it from fulfilling the request."
    default:
      return `Unknown Server Error: An unexpected server error occurred with status code ${statusCode}.`
  }
}
