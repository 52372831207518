import React from "react"
import Layout from "./src/components/Layout"
import RootElement from "./src/components/root-element"

import "./src/style/App.scss"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}
