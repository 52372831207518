import { CLPublicKey } from "casper-js-sdk"
export async function fetchAllInfo(publicKey) {
  const accountHash = CLPublicKey.fromHex(publicKey).toAccountHashStr()
  try {
    const casperAssociationInfo = await fetchCasperAssociation(
      publicKey,
      accountHash
    )
    const delegations = await fetchDelegations(publicKey)
    let fetchedValidators = []
    let total = 0

    for (const delegation of delegations.data) {
      total += parseFloat(delegation.stake) / 1000000000
      const validatorPublicKey = delegation.validator_public_key
      await new Promise(resolve => setTimeout(resolve, 500))
      const validatorData = await fetchValidator(validatorPublicKey)

      fetchedValidators.push({
        publicKey: validatorPublicKey,
        name: delegation.validator_account_info?.info.owner?.name || null,
        amount: {
          cspr: parseFloat(delegation.stake) / 1000000000,
          usd:
            (parseFloat(delegation.stake) / 1000000000) *
            casperAssociationInfo.balance.result.balance_value,
        },
        totalStake: {
          cspr: parseFloat(delegation.validator.total_stake) / 1000000000,
          usd:
            (parseFloat(delegation.validator.total_stake) / 1000000000) *
            casperAssociationInfo.balance.result.balance_value,
        },
        fee: delegation.validator.fee,
        averageScore:
          validatorData.data.average_performance?.average_score || null,
        logo:
          delegation.validator_account_info?.info.owner?.branding.logo
            .png_256 || null,
      })
    }
    let csprInfo
    try {
      const response = await fetch(
        process.env.GATSBY_URL_API + "/api/casper?days=data"
      )
      const data = await response.json()
      csprInfo = data
    } catch (error) {
      console.error("Error fetching casper price", error)
      csprInfo = { data: { current_price: 0 } }
    }
    return {
      publicKey: publicKey,
      accountHash: accountHash.split("account-hash-")[1],
      totalRewards: {
        cspr: parseFloat(casperAssociationInfo.rewards1.data) / 1000000000,
        usd:
          (parseFloat(casperAssociationInfo.rewards1.data) / 1000000000) *
          csprInfo.info.current_price,
      },
      totalBalance: {
        cspr:
          parseFloat(casperAssociationInfo.balance.result.balance_value) /
            1000000000 +
          total,
        usd:
          (parseFloat(casperAssociationInfo.balance.result.balance_value) /
            1000000000 +
            total) *
          csprInfo.info.current_price,
      },
      liquid: {
        cspr:
          parseFloat(casperAssociationInfo.balance.result.balance_value) /
          1000000000,
        usd:
          (parseFloat(casperAssociationInfo.balance.result.balance_value) /
            1000000000) *
          csprInfo.info.current_price,
      },
      stakeAsDelegator: {
        cspr: total,
        usd: total * csprInfo.info.current_price,
      },
      undelegating: {
        cspr: parseFloat(casperAssociationInfo.rewards2.data) / 1000000000,
        usd:
          (parseFloat(casperAssociationInfo.rewards2.data) / 1000000000) *
          csprInfo.info.current_price,
      },
      validators: fetchedValidators,
    }
  } catch (error) {
    console.error("Unable to fetch all info: ", error)
    return {
      // default values if fetching all info fails
      publicKey: publicKey,
      accountHash: accountHash.split("account-hash-")[1],
      totalRewards: { cspr: 0, usd: 0 },
      totalBalance: { cspr: 0, usd: 0 },
      liquid: { cspr: 0, usd: 0 },
      stakeAsDelegator: { cspr: 0, usd: 0 },
      undelegating: { cspr: 0, usd: 0 },
      validators: [],
    }
  }
}
export async function fetchDelegations(publicKey) {
  const url = `https://api.cspr.live/accounts/${publicKey}/delegations?fields=account_info,validator,validator_account_info&page=1&limit=100`
  const options = { method: "GET" }
  try {
    const response = await fetch(url, options)
    const data = await response.json()
    return data
  } catch (error) {
    console.error(`Unable to fetch delegations: ${error}`)
    return { data: [] } // default value if fetching delegations fails
  }
}
export async function fetchValidator(validatorKey) {
  const url = `https://api.cspr.live/auction-validators/${validatorKey}?fields=average_performance`
  const options = { method: "GET" }
  try {
    const response = await fetch(url, options)
    const data = await response.json()
    return data
  } catch (error) {
    console.error(`Unable to fetch validator <${validatorKey}>: ${error}`)
    return { data: { average_performance: { average_score: null } } } // default value if fetching validator fails
  }
}
export async function fetchCasperAssociation(publicKey, accountHash) {
  let balanceUrl

  const options = { method: "GET" }
  try {
    const statusUrl = "https://api.cspr.live/rpc/info_get_status"
    const statusResponse = await fetch(statusUrl)
    const statusData = await statusResponse.json()
    const stateRootHash =
      statusData.result.last_added_block_info.state_root_hash
    const purseGetItemUrl = `https://api.cspr.live/rpc/state_get_item?state_root_hash=${stateRootHash}&key=${accountHash}`

    let purse
    await fetch(purseGetItemUrl)
      .then(response => response.json())
      .then(data => {
        purse = data.result.stored_value.Account.main_purse
      })
      .catch(error => console.error(error))

    if (!purse) {
      return {
        balance: 0,
        rewards1: 0,
        rewards2: 0,
      }
    }

    balanceUrl = `https://api.cspr.live/rpc/state_get_balance?state_root_hash=${stateRootHash}&purse_uref=${purse}`

    const totalRewardsUrl1 = `https://api.cspr.live/delegators/${publicKey}/total-rewards`
    const totalRewardsUrl2 = `https://api.cspr.live/validators/${publicKey}/total-rewards`

    const [balanceResponse, rewardsResponse1, rewardsResponse2] =
      await Promise.all([
        fetch(balanceUrl, options),
        fetch(totalRewardsUrl1, options),
        fetch(totalRewardsUrl2, options),
      ])

    const balanceData = await balanceResponse.json()
    const rewardsData1 = await rewardsResponse1.json()
    const rewardsData2 = await rewardsResponse2.json()

    return {
      balance: balanceData,
      rewards1: rewardsData1,
      rewards2: rewardsData2,
    }
  } catch (error) {
    console.error(`Unable to fetch data: ${error}`)
    return {
      balance: { result: { balance_value: 0 } },
      rewards1: { data: 0 },
      rewards2: { data: 0 },
    } // default values if fetching association data fails
  }
}
